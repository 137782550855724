@import "./colors.scss";

.full-size {
  position: relative;
  width: 100%;
  height: 100%;
}

.absolute {
  position: absolute;
  left: 0;
  top: 0;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
  width: 100%;
}

.sub-h1 {
  margin: 0;
  position: absolute;
  right: 0;
  bottom: -16px;
  line-height: 30px;
  text-align: right;
}

.m-auto {
  margin: auto;
}

.o-auto {
  overflow: auto;
}

.grow {
  flex: 1;
}

.c-win {
  color: green;
}

.c-lose {
  color: red;
}

$spacer: 1rem;
.mb-0 { margin-bottom: 0!important }
.mb-1 { margin-bottom: 0.25 *$spacer!important }
.mb-2 { margin-bottom: 0.5  *$spacer!important }
.mb-3 { margin-bottom: 1    *$spacer!important }
.mb-4 { margin-bottom: 1.5  *$spacer!important }
.mb-5 { margin-bottom: 3    *$spacer!important }

.mt-0 { margin-top: 0!important }
.mt-1 { margin-top: 0.25 *$spacer!important }
.mt-2 { margin-top: 0.5  *$spacer!important }
.mt-3 { margin-top: 1    *$spacer!important }
.mt-4 { margin-top: 1.5  *$spacer!important }
.mt-5 { margin-top: 3    *$spacer!important }

.ml-0 { margin-left: 0!important }
.ml-1 { margin-left: 0.25 *$spacer!important }
.ml-2 { margin-left: 0.5  *$spacer!important }
.ml-3 { margin-left: 1    *$spacer!important }
.ml-4 { margin-left: 1.5  *$spacer!important }
.ml-5 { margin-left: 3    *$spacer!important }

.mr-0 { margin-right: 0!important }
.mr-1 { margin-right: 0.25 *$spacer!important }
.mr-2 { margin-right: 0.5  *$spacer!important }
.mr-3 { margin-right: 1    *$spacer!important }
.mr-4 { margin-right: 1.5  *$spacer!important }
.mr-5 { margin-right: 3    *$spacer!important }

.fs-2 {
  font-size: 18px;
}


.scroller {
  scrollbar-width: thin;
  scrollbar-color: $subtle transparent;

  &::-webkit-scrollbar {
    background: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $subtle;
    border-radius: 16px;
    opacity: 0.2;
    width: 8px;
  }
}

.no-scroller {
  scrollbar-width: none;
  scrollbar-color: transparent;

  &::-webkit-scrollbar {
    background: none;
    width: 0;
  }
}

