@import "../colors.scss";

.chat-view {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  .chat-upper {
    position: relative;
    width: 100%;
    min-height: 0;
    flex: 1;
  }

  .chat-history {
    position: relative;
    display: flex;
    overflow: auto;
    flex-direction: column-reverse;
    height: 100%;
    margin-right: 72px;
  }

  .chat-feeds-container {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    
    font-size: 36px;

    .chat-feeds-scroller {
      position: relative;
      max-height: 100%;
      overflow: auto;
    }

    .chat-feeds {
      padding-right: 16px;
    }

    .tooltip-container {
      font-size: 16px;
    }

    .feed-icon {
      position: relative;
      height: 36px;
      width: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 30px;
      border-radius: 8px;
      margin-bottom: 8px;

      .anticon {
        font-size: 36px;
      }
    }

    .unread-badge {
      position: absolute;
      top: -4px;
      right: -4px;
      font-size: 12px;
      line-height: 12px;
      background: red;
      padding: 2px 4px;
      color: white;
      border-radius: 4px;
      text-align: center;

      &.subtle {
        top: 0;
        background: rgba(#A7839F, 0.8);
        color: rgba($text, 0.8);
      }
    }

    .anticon {
      outline: none;
    }

    .feed {
      position: relative;
      cursor: pointer;

      &.active {
        cursor: default;

        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 70%;
          right: -8px;
          top: 15%;
          border-radius: 8px;
          background: rgba($text, 0.8);
          box-sizing: border-box;
        }
      }
    }
  }

  .chat-message {
    margin: 10px 0;

    .msg-time {
      margin-right: 0.75em;
      color: rgba($text, 0.2);
    }

    .msg-author {
      font-weight: bold;
      cursor: pointer;
    }

    .msg-content {
      word-break: break-all;
    }
  }

  .chat-hint {
    margin: 20px 0;
    color: rgba($text, 0.4);
  }

  .chat-input {
    display: flex;
    position: relative;

    input {
      flex: 1;
      background: rgba($ambient, 0.4);
      border: 1px rgba($subtle, 0.1) solid;
      font-size: 18px;
      padding: 8px;
      padding-right: 40px;
      min-width: 0;
      border-radius: 8px;
      outline: none;
      color: rgba($text, 0.7);

      &:focus {
        border: 1px rgba($subtle, 0.2) solid;
      }

      &::placeholder {
        font-style: italic;
        opacity: 0.5;
      }
    }

    .send {
      position: absolute;
      height: 100%;
      right: 0;
      padding: 0 12px;

      display: flex;
      align-items: center;
      cursor: pointer;

      outline: none;
      &:hover, &:active {
        color: rgba($text, 0.9);
      }

      &[disabled] {
        cursor: default;
        color: rgba($text, 0.2);
      }
    }
  }
}

/*

::-webkit-scrollbar — the entire scrollbar.
::-webkit-scrollbar-button — the buttons on the scrollbar (arrows pointing upwards and downwards).
::-webkit-scrollbar-thumb — the draggable scrolling handle.
::-webkit-scrollbar-track — the track (progress bar) of the scrollbar.
::-webkit-scrollbar-track-piece — the part of the track (progress bar) not covered by the handle.
::-webkit-scrollbar-corner — the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet.
::-webkit-resizer — the draggable resizing handle that appears at the bottom corner of some elements.

*/
