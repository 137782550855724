@import "../../colors.scss";

.player-modal, .tip-modal {
  h1 {
    font-weight: 100;
  }
}

.player-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .i-slot {
    margin: 8px;

    .label {
      opacity: 0.8;
    }

    .value {
      font-weight: bold;
    }
  }
}

.bet-chart {
  margin-top: 2rem;

  .rv-crosshair__inner__content {
    background: rgba($ambient, 0.7);
  }

  .chart-tooltip {
    white-space: nowrap;
    color: rgba($text, 0.8);

    .label {
      margin: 4px 0;

      &.subtle {
        opacity: 0.6;
      }
    }
  }

  .th-button {
    min-width: 150px;
  }
}
