@import "./colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $ambient;
  color: $text;
}

code, .code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  margin: 0;
}


.banned {
  display: grid;
  place-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  font-size: min(100px, 20vw);
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(255, 220, 220, 0.8);
  text-shadow: 0 0 12px #0000008c;
  background: linear-gradient(-45deg, #a70000 25%, #8b0000 25%, #8b0000 50%, #a70000 50%, #a70000 75%, #8b0000 75%);
	background-size: 27px 27px;
  animation: astripe 20s linear infinite;
}

@keyframes astripe {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}
