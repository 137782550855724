$ambient: #0E152D;
$ambient-contrast: #0d1223;

$ambientError: mix($ambient, red, 60%);

$text: rgba(255, 228, 228, 0.5);
$textBright: rgba(255, 228, 235, 0.8);

$textError: mix($text, red, 60%);

$subtle: rgba(255, 228, 228, 0.12);

$tooltipBackground: rgba(13, 18, 35, 0.5);
$tooltipBorder: $subtle;
