@import "../colors.scss";

$modal-blur: 12px;
.modal-bg-wrapper {
  filter: blur(0px);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  filter: blur(0);
  transition: filter 0.5s ease;

  &.active {
    filter: blur($modal-blur);
    transition: filter 0.25s ease;
  }
}

.modal-provider {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;

  .modal {
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .modal-background {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .modal-margin {
    max-height: 100%;
    padding: 50px 0;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.active {
    pointer-events: all;

    .modal {
      opacity: 1;
    }
  }
}

.modal {
  // width: 100%;
  background: rgba($ambient, 0.5);
  border-radius: 24px;
  overflow: hidden;

  width: 800px;
  @media (max-width: 1400px) {
    width: 600px;
  }

  @media (max-width: 1100px) {
    width: 500px;
  }

  @media (max-width: 600px) {
    width: calc(100%);
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;

    background: rgba($ambient, 0.9);

    h1 {
      margin: 0;
      font-size: 1.5em;
    }

    .anticon {
      font-size: 24px;
    }
    
    .detail {
      margin: 0 1rem;
      color: rgba($subtle, 0.3);
    }
  }

  .modal-content {
    position: relative;
    padding: 24px;
  }
}

body {
  backface-visibility: hidden;
  // -webkit-transform: translateZ(0) scale(1.0, 1.0);
  // transform: translateZ(0);
}
