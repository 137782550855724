@import "../colors.scss";

$innerPadding: 12px;
$primaryFontSize: 18px;
$secondaryFontSize: 16px;

@mixin theme {
  background: rgba($ambient, 0.4);
  border: 2px solid rgba($text, 0.1);
  border-radius: 24px;
  margin-bottom: 12px;
  color: $text;

  transition: background 0.15s ease;

  &.shorty {
    margin-bottom: 12px;
  }
}

.error, .input-container.error {
  background: rgba($ambientError, 0.4);
}

.error-fg {
  color: $textError;
}

.form-prelabel {
  padding: 8px 16px 8px 0;
}

.nomargin {
  margin: 0 !important;
}

.clickable {
  cursor: pointer;
}

.unclickable {
  cursor: default !important;
}

.input-container {
  @include theme;

  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0 24px 0 0;
  min-height: 46px;

  &.disabled {
    background: rgba($text, 0.05);
    border-color: rgba($text, 0.0);
    
    .label {
      background: transparent;
    }
  }

  .method-wrapper {
    display: flex;
    align-items: baseline;
    flex: 1;
    min-width: 0;
  }

  .input {
    flex: 1;
    background: none;
    border: none;
    outline: none;
    min-width: 0;
    padding: 12px 2px;

    &:not(input) {
      display: flex;
      align-items: center;

      user-select: none;
    }

    font-size: $primaryFontSize;
    color: $text;

    text-indent: 1em;

    &.right {
      text-align: right;
      justify-content: flex-end;
    }
  }

  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .suffix {
    user-select: none;
    font-size: $primaryFontSize;
    font-weight: 900;
  }

  .label {
    background: rgba($text, 0.1);
    padding: $innerPadding;
  
    font-size: $primaryFontSize;
  }

  .placeholder {
    opacity: 0.6;
    font-style: italic;
    padding-right: 4px;
  }
}

.form-options {
  position: absolute;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: rgba($ambient, 0.4);
  backdrop-filter: blur(5px);
  border: 2px solid rgba($text, 0.1);
  border-radius: 24px;
  
  transition: top 0.25s ease, opacity 0.25s ease;

  top: 32px;
  opacity: 0;
  pointer-events: none;
  &.visible {
    top: 64px;
    opacity: 1;
    pointer-events: all;
  }

  overflow: hidden;

  .option {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    transition: background-color 0.15s ease;
    &:hover, &.selected {
      background-color: rgba($text, 0.1);
    }

    > span {
      box-sizing: border-box;
      padding: 10px 18px;
    }

    .option-help {
      font-size: 1.2em;
      transition: background-color 0.15s ease;

      &:hover {
        background-color: rgba($text, 0.1);
      }
    }
  }
}

.inline-action {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.5;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

@media (max-width: 500px) {
  .input-container {
    .input {
      font-size: $secondaryFontSize;
    }

    .suffix {
      font-size: $secondaryFontSize;
    }
  }

  .label {
    font-size: $secondaryFontSize;
  }
}

.th-button {
  @include theme;

  display: block;
  padding: $innerPadding;
  font-size: $primaryFontSize;

  cursor: pointer;
  user-select: none;
  outline: none;

  transition: background-color 100ms ease-out, border-color 100ms ease-out;

  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background: rgba($text, 0.1);
    border-color: rgba($text, 0.0);
  }

  &[disabled] {
    cursor: default;
    background: rgba($text, 0.05);
    border-color: rgba($text, 0.0);
    color: rgba($text, 0.1);
  }

  &.full-card {
    margin: auto;

    width: min(100%, 300px);
    // max-width: 20vw;
  }
}
