$blobSize: max(30vw, 30vh);
$blobISize: min(-30vw, -30vh);

.blobs {
  position: fixed;
  width: 100%;
  height: 100%;

  overflow: hidden;
  filter: blur(120px);
  pointer-events: none;
}

.blob {
  position: absolute;
  
  width: $blobSize;
  height: $blobSize;
  border-radius: $blobSize;

  left: calc(#{$blobISize} / 2);
  top: calc(#{$blobISize} / 2);

  transform-origin: center;
}
