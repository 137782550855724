@import "../colors.scss";

$gutter: 50px;
$radius: 45px;

.kbit-header {
  display: flex;
  height: $gutter;
  align-items: center;
  padding: 0 $gutter;
  margin: $gutter/4 0;

  img {
    height: 70%;
    margin: 0 0.5rem;
  }

  h1 {
    cursor: pointer;
  }

  .dev-badge {
    margin-left: 1em;
    background: #7248f3;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid $subtle;
  }

  .header-info {
    font-weight: bold;
    margin-left: 1.5em;
    text-transform: uppercase;

    &.btn {
      cursor: pointer;
    }
  }

  .header-out {
    margin-left: 0.5em;
    cursor: pointer;
  }

  .pending {
    color: rgba(orange, 0.5);
    text-shadow: 0 0 1px rgba(orange, 0.5);
  }
}

.tip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.kbit-layout {
  position: absolute;
  width: calc(100% - #{$gutter*4});
  height: calc(100% - #{$gutter*3.5});
  display: grid;
  gap: $gutter;
  padding: 0 $gutter $gutter;

  grid-template-areas: "graph act  play"
                       "multi  multi play";
  grid-template-columns: repeat(3, 33.3333%);
  grid-template-rows: 45% 55%;
}

.card {
  position: relative;
  background-color: rgba($ambient-contrast, 0.45);
  border-radius: $radius;

  box-shadow: 0 4px 20px 0 rgba($ambient, 0.6);

  padding: 40px;
}

.notify-popup {
  position: fixed;
  margin-left: $gutter;
  right: $gutter;
  top: 12px;
  background: rgba(0, 120, 150, 0.5);
  padding: 16px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  font-weight: bold;
  font-size: 1.25em;
}

@media (max-width: 1100px) {
  .kbit-layout {
    width: calc(100% - #{$gutter*3});
    height: calc(100% - #{$gutter*3.5});

    grid-template-areas: "graph act"
                         "multi  multi";
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 45% 55%;
  }

  .players-righttab {
    display: none;
  }
}

@media (max-width: 850px) {
  .kbit-header {
    padding: 0 $gutter/2;
  }

  .notify-popup {
    right: $gutter/2;
    margin-left: $gutter/2;
  }

  .kbit-layout {
    width: calc(100% - #{$gutter*3/2});
    height: calc(100% - #{$gutter*5/2});

    gap: $gutter / 2;
    padding: 0 $gutter / 2 $gutter / 2;
  }

  .card {
    border-radius: $radius / 2;

    padding: 30px;
  }
}

@media (max-width: 700px) {
  .kbit-layout {
    width: calc(100% - #{$gutter*2/2});
    height: calc(100% - #{$gutter*6/2});

    grid-template-areas: "graph"
                         "act"
                         "multi";
    grid-template-columns: 100%;
    grid-template-rows: 30% 30% 40%;
  }

  .players-righttab {
    display: none;
  }
}

@media (max-width: 700px) {
  .card {
    border-radius: $radius / 2;

    padding: 20px;
  }
  
  .kbit-layout {
    height: auto;
    grid-template-rows: unset;
  }

  .card {
    min-height: 200px;
  }
}

@media (max-width: 500px) {
  .kbit-header {
    h1 {
      display: none;
    }
  }
}
