@import "../colors.scss";

.combo-view {
  display: flex;
  flex-direction: row;
  height: 100%;

  .tabs {
    height: 100%;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    position: relative;
    left: -20px;
    margin-left: -10px;

    border-right: 1px solid $subtle;
    
    > div {
      color: $textBright;
      cursor: pointer;
      transform: scale(-1);
      writing-mode: vertical-rl;
      user-select: none;
      padding: 10px;

      text-align: center;
      min-height: 60px;

      opacity: 0.6;

      &.active {
        cursor: default;
        font-weight: 900;
        opacity: 1;
      }

      &:lang(zh) {
        transform: scale(1)!important;
      }
    }

    @media (max-width: 850px) {
      left: -15px;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    
    .combo-content {
      max-height: calc(100% - 30px);
    }

    .tabs {
      flex-direction: row;
      width: 100%;
      height: 40px;
      left: 0;
      top: -10px;

      margin-left: 0;
      margin-top: -10px;

      border-right: none;
      border-bottom: 1px solid $subtle;

      > div {
        transform: unset;
        writing-mode: unset;

        min-width: 60px;
        min-height: unset;
      }
    }
  }

  .combo-content {
    flex: 1;
    min-width: 0;
    // border: 1px white solid;
  }
}
